import http from '../../http'
import { withColumns } from '../../context/Columns'
import { withFilter } from '../../context/Filter'
import { withSharedStorage } from 'context/SharedStorage'
import DownloadButton from '../../components/DownloadButton'
import buildIcon from '../../helpers/buildIcon'

const ExportTable = ( {
  action,
  withColumns,
  withSharedStorage: { storage: { params }, updateStorege },
  withFilter: { state: { model = {}, steps = [] } }
} ) => {
  const { endpoint = '/api/report/export' } = action

  const Icon = buildIcon( action.icon )

  function downloadTable( cb ) {

    const cols = withColumns.columns
      .filter( ( col ) => col.view )

    let stages = []
    if ( action.useFilter )
      stages = [ ...stages, ...steps ]

    if ( action.useParams )
      stages = [ ...stages, params ]

    http
      .request( {
        url: endpoint,
        method: 'POST',
        responseType: 'blob',
        data: [
          ...stages,
          { __columns: cols }
        ],
        silence: true
      } )
      .then( cb )
  }

  return (
    <>
      <DownloadButton
        title={ action.title }
        uri={ action.endpoint }
        prefix={ action.prefix }
        useFilter={ action.useFilter }
        action={ downloadTable }
      >
        <Icon />
      </DownloadButton>
    </>
  )
}

export default withSharedStorage( withColumns( withFilter( ExportTable ) ) )
